.Heading {
  display: flex;
  text-transform: uppercase;
  text-align: center;
  margin: 1rem 0;
}

.headingContainer {
  display: flex;
  justify-content: center;
}

.underline {
  border-bottom: 4px solid var(--primary);
  margin-bottom: 1rem;
}

.hashLinkContainer {
  position: relative;
}

.hashLinkContainer > a {
  position: absolute;
  left: -2rem;
}

.icon {
  width: 1rem;
  line-height: 2.25;
  height: 2rem;
  margin: -0.2rem 0.5rem;
  outline: none;
}

/* Hide on mobile always */
@media screen and (--small-viewport) {
  .hashLinkContainer a {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
}

.Heading .icon {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s linear opacity, 0.2s linear visibility;
}

@media not screen and (--small-viewport) {
  .Heading:hover .icon,
  .Heading a:focus .icon {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: 0.2s linear opacity, 0.2s linear visibility;
  }
}
