.SuccessStory {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 3rem 1rem;
  padding-top: 60px;
  position: relative;
  max-width: 320px;
  height: 670px;
}

.image {
  box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  height: 200px;
  width: 200px;
}

.quote {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  padding: 2rem;
  padding-top: 160px;
  border: 3px solid var(--primary);
}

.quote > blockquote {
  margin: 1rem 0;
  font-style: italic;
  font-size: 1rem;
}

.title {
  text-align: center;
  font-size: 1rem;
}

@media screen and (--medium-viewport) {
  .SuccessStory {
    height: 575px;
  }
}

@media screen and (--small-viewport) {
  .SuccessStory {
    height: auto;
  }
}
