.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
}

.columnsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* the individual columns */
.columnsContainer > * {
  margin: 1rem;
}
