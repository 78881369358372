.HeroBanner {
  padding-top: 5rem;
  min-height: 60vh;
  text-shadow: #111111 0 0 15px;
}

.HeroBanner.smallHero {
  min-height: 35vh;
}

.underline {
  border-bottom: 4px solid var(--primary);
  margin-bottom: 1rem;
  text-align: center;
}
