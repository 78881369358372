.PartnerLogoLink {
  text-align: center;
  width: 200px;
}

.logo {
  width: 100px; /* medium size */
  height: 100px; /* medium size */
  line-height: 50px;
  text-align: center;
  filter: grayscale(100%);
  transition: filter 0.2s linear;
}

.logo:hover {
  filter: grayscale(0);
  transition: filter 0.2s linear;
}

.logo.small {
  width: 50px;
  height: 50px;
}

.logo.large {
  width: 150px;
  height: 150px;
}
